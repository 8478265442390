.dilli {
  font-size: 50px;
}

.bgreen {
  background-color: #006500;
  height: 30px;
}
.bmenucolur {
  /* background-color: #b9e0b9; */
}
.greenmenudisplay {
  display: flex;
}
.greenmenudisplay1 {
  display: flex;
  margin: auto;
  justify-content: space-evenly;
}
.greenmenudisplay2 {
  display: flex;
  width: 10%;
  justify-content: space-evenly;
}
.greenmenudisplay5 {
  display: flex;
  width: 40%;
  justify-content: flex-end;
  margin-top: 20px;
}
.line-container-left {
  width: 70%;
  border-bottom: 1px solid green;
}
.line-container-right {
  width: 70%;
  border-bottom: 1px solid green;
}
.greenmenudisplay6 {
  display: flex;
  width: 20%;
  justify-content: center;
}
.greenmenudisplay7 {
  display: flex;
  width: 40%;
  margin-top: 20px;
}
.text {
  font-family: poppins;
  color: white;
  font-size: 16px;
}
.texttopmenu {
  font-family: "Poppins";
  color: white;
  font-size: 12px;
  display: contents;
}
.texttitle {
  font-family: poppins;
  color: #707070;
  font-weight: 500;
  font-size: 22px;
}
.img {
  width: 120px;
  margin-top: -26px;
}
.searchbar {
  background-color: #b9e0b978;
  border-radius: 10px;
  padding: 0px 9px;
  margin-top: 4px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.arrow {
  border: solid gray;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
}
