:root {
  --primary-color: hsl(263, 70%, 50%);
  --table-header-bg: #ffffff;
  --table-header-color: black;
  --table-odd-row-bg: #f9faf2;
  --table-even-row-bg: #f9faf2;
  --phone-input-height: 47px;
  --phone-input-font-size: 16px;
  --react-select-height: 53px;
  --sidebar-icon-bg-color: "#2b2b2b";
  --sidebar-title-color: "#adadad";
  --sidebar-title-hover-color: "#ffffff";
  --sidebar-title-active-color: "#ffffff";
  --label-bg-color: "#fff";
}

body {
  margin: 0;
  font-family: "Poppins" !important;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: "Poppins";
}
html,
body {
  height: 100%;
}

/* These styles disable body scrolling if you are using <ScrollView> */
body {
  overflow: hidden;
}

/* These styles make the root element full-height */
#root {
  display: flex;
  height: 100%;
}
th.rc-table-cell {
  border-bottom: 1px solid green;
}
.sbContentHolder-thumb-vertical {
  z-index: 2;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0.3);
}

.PhoneInputInput {
  height: var(--phone-input-height) !important;
  margin-right: 5px;
  font-size: var(--phone-input-font-size);
  font-weight: medium;
  border: none;
  outline: none;
}

.select__control {
  border: none !important;
  outline: none !important;
  min-height: var(--react-select-height) !important;
  margin-right: 2px;
}

.select__control--is-focused {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.rc-table table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.rc-table-thead {
  /* border-top: 1px solid #d5d5d5; */
  /* border-bottom: 1px solid #1b9d67; */
  border: 1px solid #1b9d67;
}
.rc-table-thead .rc-table-cell {
  /* margin-top: 20px; */
  background-color: var(--table-header-bg);
  color: var(--table-header-color) !important;
}

.rc-table-tbody .rc-table-row {
  background-color: var(--table-odd-row-bg);
}

.rc-table-tbody .rc-table-row:nth-of-type(even) {
  background-color: var(--table-even-row-bg);
  padding-top: 20px;
}

.rc-table-tbody .rc-table-row:last-child {
  border-bottom: 1px solid #d5d5d5;
}

/* .rc-table-thead .rc-table-cell {
  border-right: 1px solid #d5d5d5;
}

.rc-table-thead .rc-table-cell:last-child {
  border-right: 0px !important;
} */

/* .rc-table-tbody .rc-table-cell {
  border-right: 1px solid #d5d5d5;
} */

.rc-table-tbody .rc-table-cell:last-child {
  border-right: 0px !important;
}

.rc-table-cell {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  margin: 20px;
}

.rc-pagination-item-active {
  border-color: var(--primary-color) !important;
}

.rc-pagination-item-active a {
  color: var(--primary-color) !important;
}

.Dropdown-control {
  width: 65px !important;
}

.pro-icon-wrapper {
  background-color: var(--sidebar-icon-bg-color) !important;
}

.pro-menu-item {
  color: var(--sidebar-title-color);
}

.pro-menu-item:hover {
  color: var(--sidebar-title-hover-color) !important;
}

.pro-menu-item.active {
  color: var(--sidebar-title-active-color) !important;
}

.demo-app {
  display: flex;
  min-height: 100%;
  font-family: "Poppins";
  font-size: 14px;
}

.demo-app-main {
  flex-grow: 1;
}

.fc {
  max-width: 1100px;
  margin: 0 auto;
}

.jodit-container:not(.jodit_inline) {
  border: none !important;
  border-bottom-right-radius: 6px;
}

.jodit-placeholder {
  display: none !important;
}

.DayPickerInput-Overlay {
  z-index: 10001 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}
#numberBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#numberBox::-webkit-inner-spin-button,
#numberBox::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

#numberBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none;
  outline: none;
}

#numberBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}
#textBox {
  border: none;
  outline: none;
  overflow: hidden;
}

#textBox:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textBox:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

#textarea {
  border: none;
  outline: none;
  resize: none;
  padding-top: 10px;
}

#textarea:hover {
  background-color: var(--label-bg-color) !important;
  border: none !important;
  outline: none !important;
}

#textarea:focus {
  border-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.header-fixed {
  background-color: #292c2f;
  box-shadow: 0 1px 1px #ccc;
  padding: 20px 40px;
  height: 80px;
  color: #ffffff;
  box-sizing: border-box;
  top: -100px;

  -webkit-transition: top 0.3s;
  transition: top 0.3s;
}

.header-fixed .header-limiter {
  max-width: 1200px;
  text-align: center;
  margin: 0 auto;
}

/*	The header placeholder. It is displayed when the header is fixed to the top of the
	browser window, in order to prevent the content of the page from jumping up. */

.header-fixed-placeholder {
  height: 80px;
  display: none;
}

/* Logo */

.header-fixed .header-limiter h1 {
  float: left;
  font: normal 28px Cookie, Arial, Helvetica, sans-serif;
  line-height: 40px;
  margin: 0;
}

.header-fixed .header-limiter h1 span {
  color: #5383d3;
}

/* The navigation links */

.header-fixed .header-limiter a {
  color: #ffffff;
  text-decoration: none;
}

.header-fixed .header-limiter nav {
  font: 16px Arial, Helvetica, sans-serif;
  line-height: 40px;
  float: right;
}

.header-fixed .header-limiter nav a {
  display: inline-block;
  padding: 0 5px;
  text-decoration: none;
  color: #ffffff;
  opacity: 0.9;
}

.header-fixed .header-limiter nav a:hover {
  opacity: 1;
}

.header-fixed .header-limiter nav a.selected {
  color: #608bd2;
  pointer-events: none;
  opacity: 1;
}

/* Fixed version of the header */

body.fixed .header-fixed {
  padding: 10px 40px;
  height: 50px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

body.fixed .header-fixed-placeholder {
  display: block;
}

body.fixed .header-fixed .header-limiter h1 {
  font-size: 24px;
  line-height: 30px;
}

body.fixed .header-fixed .header-limiter nav {
  line-height: 28px;
  font-size: 13px;
}

/* Making the header responsive */

@media all and (max-width: 600px) {
  .header-fixed {
    padding: 20px 0;
    height: 75px;
  }

  .header-fixed .header-limiter h1 {
    float: none;
    margin: -8px 0 10px;
    text-align: center;
    font-size: 24px;
    line-height: 1;
  }

  .header-fixed .header-limiter nav {
    line-height: 1;
    float: none;
  }

  .header-fixed .header-limiter nav a {
    font-size: 13px;
  }

  body.fixed .header-fixed {
    display: none;
  }
}
.Loki {
  max-width: 100%;
  margin: 0 auto;
}

.LokiSteps {
  width: 100%;
  position: relative;
  display: table;
  table-layout: fixed;
  text-align: center;
  vertical-align: middle;
  border-collapse: separate;
  margin-left: 0px;
  padding-left: 0;
  padding-top: 20px;
}

.Loki-Actions {
  display: block;
  padding: 20px 0;
}

.LokiSteps .LokiStep {
  display: table-cell;
  position: relative;
  float: none;
  padding: 0;
  width: 1%;
}

.LokiSteps .LokiStep .LokiStep-Icon {
  background-color: white;
  border-radius: 50%;
  border: 1px solid #ccc;
  color: #ccc;
  font-size: 24px;
  height: 64px;
  margin: 0 auto;
  position: relative;
  width: 64px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LokiSteps .LokiStep .LokiStep-Link.disabled {
  cursor: not-allowed;
}

.LokiSteps .LokiStep.LokiStep-Active .LokiStep-Icon {
  border-color: #75b2f5;
  color: #75b2f5;
}

.LokiSteps .LokiStep.LokiStep-Complete .LokiStep-Icon {
  border-color: #05ae0e;
  color: #05ae0e;
}

.LokiSteps .LokiStep:after {
  left: 50%;
  background-color: #ccc;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
  width: 100%;
  top: 35px;
}

.LokiSteps .LokiStep:last-child:after {
  display: none;
}
/*
	 We are clearing the body's margin and padding, so that the header fits properly.
	 We are also adding a height to demonstrate the scrolling behavior. You can remove
	 these styles.
 */
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #386c45 !important;
  cursor: pointer !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #386c45 !important;
  cursor: pointer !important;
}
.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #386c45 !important;
  cursor: pointer !important;
}
.divider-transition-header-left {
  width: 100%;
  height: 100%;
  background: #519007e0;
  border-radius: 3px;
  transition: width 1s ease-in-out;
}

.main-divider-transition-header-left {
  width: 0%;
  height: 100%;
  background: #519007e0;
  border-radius: 3px;
  transition: width 1s ease-in-out;
}
.divider-transition-header-right {
  width: 100%;
  height: 100%;
  background: #519007e0;
  border-radius: 3px;
  transition: width 1s ease-in-out;
}

.main-divider-transition-header-right {
  width: 0%;
  height: 100%;
  background: #519007e0;
  border-radius: 3px;
  transition: width 1s ease-in-out;
  transform: rotate(180deg);
}

.forest-glance-transition-header-left {
  width: 0%;
  height: 100%;
  background: #ffffff;
  border-radius: 3px;
  margin-left: 6%;
  transition: width 1s ease-in-out;
}

.main-forest-glance-transition-header-left {
  width: 40%;
  height: 100%;
  margin-left: 6%;
  background: #519007e0;
  border-radius: 3px;
  transition: width 1s ease-in-out;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  border-bottom: 2px solid #3a6a01;
}
.ant-tabs-tab.ant-tabs-tab-active {
  background: #3a6a01;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: "white";
  width: 230px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
}
.ant-tabs-ink-bar {
  background: transparent !important;
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 !important;
}
.ant-tabs-tab {
  width: 204px;
  /* padding-left: 10px; */
  justify-content: center;
}
.ant-tabs-tab:hover {
  color: #3a6a01 !important;
}
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  font-weight: bolder;
  font-size: large;
}
.ant-collapse-icon-position-end > .ant-collapse-item > .ant-collapse-header {
  border-bottom: 2px solid rgb(157, 209, 157);
}
.ant-menu-horizontal {
  justify-content: center;
}
/* Home - About US */
h1 {
  margin: 20px;
  color: #173a2a;
  font-weight: 900;
}
.block {
}
.text {
  color: rgb(243, 250, 255);
  font-size: 15px;
}
.abouttext1 {
  line-height: 24px;
  color: #173a2a;
  font-size: 15px;
  text-align: justify;
  margin: 20px;
}
@media only screen and (max-width: 600px) {
  .abouttext1 {
    line-height: 16px;
    color: #173a2a;
    font-size: 12px;
    text-align: justify;
    margin: -20px;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .logowidth {
    width: 70px;
    margin-left: -170px;
  }
}
@media only screen and (max-width: 600px) {
  .logowidthfooter {
    width: 90px;
    margin-left: 204px;
    margin-top: 26px;
  }
}
@media only screen and (max-width: 600px) {
  .logoheightfooter {
    width: 100%;
    height: 460px;
  }
  .logoheightfootertn {
    width: 100%;
    height: 520px;
  }
}
@media only screen and (max-width: 600px) {
  .forestglanceheight {
    height: 600px;
  }
}
@media only screen and (max-width: 600px) {
  .galleryswipe {
    height: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .mobilehead {
    font-weight: 300;
    top: 94px;
    width: 292px;
    margin-left: 104px;
    position: relative;
    font-size: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .mobilehead1 {
    font-weight: 300;
    top: 94px;
    width: 292px;
    margin-left: 104px;
    position: relative;
    font-size: 20px;
  }
}

.headingtext {
  font-size: 30px;
}
@media only screen and (max-width: 600px) {
  .headingtext {
    font-size: 25px;
    margin-bottom: 27px;
    margin-left: -10px;
  }
}
@media only screen and (max-width: 600px) {
  .headingtext1 {
    font-size: 30px;
    /* margin-bottom: 27px; */
  }
}

img {
  border-radius: 8px;
  display: flex;
  justify-content: flex-end;
}
#my-img:hover {
  content: url("https://images.ctfassets.net/hrltx12pl8hq/5qusjAtcAaetdPsing4jR6/2db2f75abd2840caa09d21312b4fc6c8/animals-wildlife-shutterstock_1066200380.jpg?fit=fill&w=480&h=320");
}
.parent {
  position: relative;
  top: 0;
  left: 0;
  align-self: center;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
  box-shadow: "0px 0px 20px rgb(229 218 255)";
  height: 350px;
}

@media only screen and (max-width: 600px) {
  .image1 {
    position: relative;
    top: 0;
    left: 0;
    box-shadow: "0px 0px 20px rgb(229 218 255)";
    height: 150px;
  }
}
.image2 {
  /* border-radius: 8px; */
  position: absolute;
  top: calc(100% - 30%);
  left: calc(100% - 45%);
  width: 300px;
}
@media only screen and (max-width: 600px) {
  .image2 {
    /* border-radius: 8px; */
    position: absolute;
    top: calc(100% - 30%);
    left: calc(100% - 55%);
    width: 150px;
  }
}

.btn {
  /* margin-top: 2vw; */
  border: 1px solid green;
  /* border-color: green; */
  background-color: transparent;
  /* width: 8vw;
        height: 2.5vw; */
  font-size: 12px;
  color: green;
  border-radius: 0.2vw;
  cursor: pointer;
  margin: 20px;
}
@media only screen and (max-width: 600px) {
  .btn {
    /* margin-top: 2vw; */
    border: 1px solid green;
    /* border-color: green; */
    background-color: transparent;
    /* width: 8vw;
          height: 2.5vw; */
    font-size: 12px;
    color: green;
    border-radius: 0.2vw;
    cursor: pointer;
    margin: 18px;
    margin-top: 37px;
    margin-left: -17px;
  }
}

.btn:hover {
  background: green;
  color: white;
}
.success {
  border-color: #04aa6d;
  color: green;
}

.ant-menu-submenu-popup {
  background: #04aa6d !important;
}
.ant-menu-submenu
  .ant-menu-submenu-popup
  .ant-menu
  .ant-menu-light
  .ant-menu-submenu-placement-bottomLeft {
  background: #04aa6d !important;
}

:root {
  --random-x: 100px;
  --random-y: 200px;
}
.canvas {
  position: relative;
  width: 500px;
  height: 500px;
  margin: 0 auto;
}
.butterfly-body {
  width: 4px;
  height: 25px;
  border-radius: 10px;
  background-color: black;
}
.butterfly-body:before {
  position: absolute;
  top: -5px;
  left: -1px;
  width: 1px;
  height: 6px;
  background-color: black;
  transform: rotate(-25deg);
  content: "";
}
.butterfly-body:after {
  position: absolute;
  top: -5px;
  left: 4px;
  width: 1px;
  height: 6px;
  background-color: black;
  transform: rotate(25deg);
  content: "";
}
.butterfly {
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translate(var(--random-x), var(--random-y));
  transition: transform 2s linear;
}
.butterfly-wing-1 {
  transform: rotateY(60deg);
  animation: Wing1 0.5s infinite;
}
.butterfly-wing-1:before {
  position: absolute;
  top: -9px;
  left: 5px;
  display: block;
  width: 30px;
  height: 20px;
  transform: rotateX(0deg);
  border-radius: 20px 5px;
  background-color: green;
  content: "";
}
.butterfly-wing-1:after {
  position: absolute;
  top: 13px;
  left: 5px;
  display: block;
  width: 30px;
  height: 20px;
  transform: rotateX(-170deg);
  border-radius: 20px 5px;
  background-color: green;
  content: "";
}
.butterfly-wing-2 {
  position: relative;
  top: -1px;
  transform: rotate(180deg) rotateY(60deg);
  animation: Wing2 0.5s infinite;
}
.butterfly-wing-2:before {
  position: absolute;
  top: -9px;
  left: 5px;
  display: block;
  width: 30px;
  height: 20px;
  transform: rotateX(0deg);
  border-radius: 20px 5px;
  background-color: green;
  content: "";
}
.butterfly-wing-2:after {
  position: absolute;
  top: 13px;
  left: 5px;
  display: block;
  width: 30px;
  height: 20px;
  transform: rotateX(-170deg);
  border-radius: 20px 5px;
  background-color: green;
  content: "";
}
@keyframes Wing1 {
  0% {
    transform: rotateY(60deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(60deg);
  }
}
@keyframes Wing2 {
  0% {
    transform: rotate(180deg) rotateY(60deg);
  }
  50% {
    transform: rotate(180deg) rotateY(0deg);
  }
  100% {
    transform: rotate(180deg) rotateY(60deg);
  }
}
@keyframes Body {
  0% {
    transform: translate(99, 187);
  }
  20% {
    transform: translate(59, 13);
  }
  40% {
    transform: translate(199, 90);
  }
  60% {
    transform: translate(120, 67);
  }
  80% {
    transform: translate(185, 183);
  }
  100% {
    transform: translate(38, 5);
  }
}

.fca {
  background-color: #fffff8;
  padding: 20px;
}
@media only screen and (max-width: 600px) {
  .fca {
    padding: 20px;
  }
}
p {
  font-family: poppins;
  text-align: justify;
  color: #173a2a;
}
UL {
  font-family: poppins;
  text-align: justify;
}

.sharedschemes {
  border: 2px solid green;
  padding: 20px;
  border-radius: 50px;
  justify-content: "center";
  text-align: center;
  margin: 50px;
  list-style: none;
}

@media only screen and (max-width: 600px) {
  .sharedschemes {
    border: 2px solid green;
    padding: 10px;
    border-radius: 40px;
    justify-content: "center";
    text-align: center;
    margin: 20px;
    list-style: none;
    margin-left: -23px;
    width: 100%;
  }
}

.externallyAided {
  border: 2px solid grey;
  padding: 20px;
  border-radius: 25px;
  justify-content: "center";
  /*opacity:0.5;*/
  margin: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://img.freepik.com/premium-photo/saccharum-spontaneum-flower-kans-grass-wild-sugarcane-grass-flower_51012-144.jpg?w=2000");
}

@media only screen and (max-width: 600px) {
  .externallyAided {
    width: 100%;
    border: 2px solid grey;
    padding: 20px;
    border-radius: 25px;
    justify-content: "center";
    /*opacity:0.5;*/
    margin: 20px;
    margin-left: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://img.freepik.com/premium-photo/saccharum-spontaneum-flower-kans-grass-wild-sugarcane-grass-flower_51012-144.jpg?w=2000");
  }
}

.Activities {
  border: 2px solid green;
  padding: 20px;
  border-radius: 50px;
  justify-content: "center";
  text-align: center;
  margin: 50px;
  list-style: none;
}

@media only screen and (max-width: 600px) {
  .Activities {
    width: 100%;
    border: 2px solid green;
    padding: 20px;
    border-radius: 50px;
    justify-content: "center";
    text-align: center;
    right: 38px;
    position: relative;
    margin: 20px;
    list-style: none;
  }
}

.protection:nth-child(even) {
  background: #f5f5f5;

  color: black;
}
.protection:nth-child(odd) {
  background: #e6f3bb;
  color: black;
}
.protection {
  justify-content: "center";
  text-align: center;
  padding: 20px;
  border-radius: 30px;
  list-style: none;
  margin: 20px;
  height: 70px;
}

@media only screen and (max-width: 600px) {
  .protection {
    width: 100%;
    justify-content: "center";
    text-align: center;
    padding: 20px;
    border-radius: 30px;
    list-style: none;
    margin: 20px;
    height: auto;
    margin-left: -23px;
  }
}

.forestglance {
  width: 100%;
  height: 600px;
}

@media only screen and (max-width: 600px) {
  .forestglance {
    width: 100%;
    height: 600px;
    overflow: hidden;
  }
}

/* administration */

hr {
  color: green;
}
td {
  font-family: poppins;
}
th {
  font-family: poppins;
}
@media only screen and (max-width: 600px) {
  .atext {
    font-family: poppins;
    color: #002e00;
    font-size: 15px;
    font-weight: 300;
  }
}
.atext {
  font-family: poppins;
  color: #002e00;
  font-size: 25px;
  font-weight: 600;
}
.center {
  justify-content: center;
  display: flex;
}
.display {
  display: flex;
}
.displaywild {
  display: flex;
}
@media only screen and (max-width: 600px) {
  .displaywild {
    display: grid;
  }
}
.display1 {
  /* display: flex; */
  width: 20%;
}
.display2 {
  /* display: flex; */
  width: 60%;
}
.display3 {
  /* display: flex; */
  width: 40%;
}
.display4 {
  /* display: flex; */
  width: 70%;
}
.display5 {
  /* display: flex; */
  width: 35%;
}
@media only screen and (max-width: 600px) {
  .display5 {
    /* display: flex; */
    width: 100%;
    margin-bottom: 10px;
  }
}
.display6 {
  /* display: flex; */
  width: 35%;
}
@media only screen and (max-width: 600px) {
  .display6 {
    /* display: flex; */
    width: 100%;
    margin-bottom: 10px;
  }
}
.display7 {
  /* display: flex; */
  width: 35%;
}
@media only screen and (max-width: 600px) {
  .display7 {
    /* display: flex; */
    width: 100%;
    margin-bottom: 10px;
  }
}
.bcolorwild {
  background-color: #f9faf2;
  height: 265px;
  border-radius: 10px;
  margin-right: 10px;
  align-items: center;
  display: flex;
}
@media only screen and (max-width: 600px) {
  .bcolorwild {
    background-color: #f9faf2;
    height: auto;
    border-radius: 10px;
    margin-right: 10px;
    align-items: center;
    display: flex;
  }
}
.display8 {
  /* display: flex; */
  width: 50%;
}
.display9 {
  /* display: flex; */
  width: 50%;
}
.display10 {
  /* display: flex; */
  width: 25%;
}
@media only screen and (max-width: 600px) {
  .display10 {
    /* display: flex; */
    width: 100%;
    margin-bottom: 10px;
  }
}
.display11 {
  /* display: flex; */
  width: 25%;
}
@media only screen and (max-width: 600px) {
  .display11 {
    /* display: flex; */
    width: 100%;
    margin-bottom: 10px;
  }
}
.display12 {
  /* display: flex; */
  width: 25%;
}
@media only screen and (max-width: 600px) {
  .display12 {
    /* display: flex; */
    width: 100%;
    margin-bottom: 10px;
  }
}
.display13 {
  /* display: flex; */
  width: 25%;
}
@media only screen and (max-width: 600px) {
  .display13 {
    /* display: flex; */
    width: 100%;
    margin-bottom: 10px;
  }
}
.display14 {
  /* display: flex; */
  width: 50%;
}
.display15 {
  /* display: flex; */
  width: 50%;
}
.display16 {
  display: flex;
  /* width: 50%; */
  /* margin-left: 10px; */
  padding: 10px;
}
.display17 {
  display: flex;
  /* width: 50%; */
  margin-left: 10px;
}
.atext1 {
  font-family: poppins;
  color: #002e00;
  font-size: 18px;
  text-align: justify;
}

.texttable {
  font-family: poppins;
  color: #002e00;
  font-size: 15px;
  text-align: justify;
  margin: 10px;
}
@media only screen and (max-width: 600px) {
  .texttable {
    font-family: poppins;
    color: #002e00;
    font-size: 10px;
    text-align: justify;
    margin: 10px;
  }
}

.texttable1 {
  font-family: poppins;
  color: #ffff;
  font-size: 15px;
  text-align: justify;
  margin: 10px;
}
.atext2 {
  font-family: poppins;
  color: #002e00;
  font-size: 18px;
  text-align: justify;
  font-weight: 600;
}
.atext3 {
  font-family: poppins;
  color: #002e00;
  font-size: 18px;
  text-align: justify;
  font-weight: 400;
}
.bcolor {
  background-color: #f9faf2;
  height: 100px;
  border-radius: 10px;
  margin-right: 10px;
  align-items: center;
  display: flex;
}
@media only screen and (max-width: 600px) {
  .bcolor {
    background-color: #f9faf2;
    height: 110px;
    border-radius: 10px;
    margin-right: 10px;
    align-items: center;
    display: flex;
  }
}
.bcolor1 {
  background-color: #f9faf2;
  height: 265px;
  border-radius: 10px;
  margin-right: 10px;
  align-items: center;
  display: flex;
}
.bcolor2 {
  background-color: #f9faf2;
  height: 149px;
  border-radius: 10px;
  margin-right: 10px;
  align-items: center;
  display: flex;
}
.bcolor3 {
  background-color: rgb(92, 134, 92);
  height: 250px;
  border-radius: 10px;
  margin-right: 10px;
  align-items: center;
  display: flex;
}
.bcolor4 {
  background-color: #f9faf2;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 20px;
}
@media only screen and (max-width: 600px) {
  .bcolor4 {
    background-color: #f9faf2;
    height: auto;
    border-radius: 10px;
    margin-bottom: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 20px;
    padding: 10px;
  }
}

.bcolor5 {
  background-color: #f9faf2;
  height: 260px;
  border-radius: 10px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 20px;
}
.bcolor6 {
  background-color: #f9faf2;
  height: 120px;
  border-radius: 10px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 20px;
}
.bcolor7 {
  background-color: #f9faf2;
  height: 120px;
  border-radius: 10px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 20px;
}
.bcolor8 {
  background-color: #f9faf2;
  /* height: 215px;
      width: 506px; */
  border-radius: 10px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 20px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.admintext {
  font-size: 18px;
  font-family: poppins;
  font-weight: 600;
}

/* td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
} */

/* tr:nth-child(even) {
  background-color: #dddddd;
} */

/* ***************************************************** */

.Ramsar {
  border: 2px solid green;
  padding: 20px;
  border-radius: 50px;
  justify-content: "center";
  text-align: center;
  list-style: none;
  margin: 50px;
}

@media only screen and (max-width: 600px) {
  .Ramsar {
    width: 100%;
    border: 2px solid green;
    padding: 20px;
    border-radius: 50px;
    justify-content: "center";
    text-align: center;
    list-style: none;
    /* margin: 50px; */
    margin-left: -25px;
  }
}

.card {
  /* border: 2px solid green; */
  padding: 38px;
  border-radius: 20px;
  justify-content: "center";
  margin: 10px;
  /*background-color:#F9FAF2;*/

  /* box-shadow: 5px 10px #efe8e8; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
}
.wetland {
  list-style: none;
}

@media only screen and (max-width: 600px) {
  .card {
    width: 100%;
    /* border: 2px solid green; */
    padding: 20px;
    border-radius: 20px;
    justify-content: "center";
    margin: 10px;
    position: relative;
    left: -10px;
    /*background-color:#F9FAF2;*/

    /* box-shadow: 5px 10px #efe8e8; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
  }
  .wetland {
    list-style: none;
    justify-content: "center";
    position: relative;
    left: -20px;
  }
}

.tamilnadustate {
  border: 2px solid lightgrey;
  padding: 20px;
  border-radius: 8px;
  justify-content: "center";
  text-align: center;

  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 6px;

  margin: 50px;
}

.thrustarea2:nth-child(even) {
  background: #f9faf2;
  width: 45%;
  left: auto;
  padding: 20px;
  right: 0;
  border-radius: 30px;

  height: 70px;
}

.styled-list {
  list-style: none;

  padding: 0;
  margin: 0;
}

.styled-list li {
  position: relative;
  padding-left: 10px;
  list-style: none;
}

.styled-list li:nth-child(even) {
  padding-right: 10px;
  position: relative;
  padding-left: 0;
  background: #f9faf2;
}
.styled-list li:nth-child(even):before {
  left: auto;
  right: 0;
}

.thrustarea1:nth-child(odd) {
  background: #e7f6db;
  padding: 20px;
  width: 45%;
  border-radius: 30px;
  border-radius: 30px;
  height: 70px;
}

@media only screen and (max-width: 600px) {
  .thrustarea2:nth-child(even) {
    background: #f9faf2;
    width: 100%;
    padding: 20px;
    right: 0;
    border-radius: 30px;
    height: 70px;
  }

  .styled-list {
    list-style: none;

    padding: 0;
    margin: 0;
  }

  .styled-list li {
    position: relative;
    padding-left: 10px;
    list-style: none;
  }

  .styled-list li:nth-child(even) {
    padding-right: 10px;
    position: relative;
    padding-left: 0;
    background: #f9faf2;
  }
  .styled-list li:nth-child(even):before {
    right: 0;
  }

  .thrustarea1:nth-child(odd) {
    background: #e7f6db;
    padding: 20px;
    width: 100%;
    border-radius: 30px;
    border-radius: 30px;
    height: auto;
    height: auto;
  }
}

.list {
  border-width: 2px;
  border: 2px solid lightgrey;
  /*border-style: groove;*/
  padding: 30px;
  border-radius: 30px;
  justify-content: "center";

  /*margin: 50px;*/
}

.externallyAided {
  border: 2px solid grey;
  padding: 20px;
  border-radius: 25px;
  justify-content: "center";
  /*opacity:0.5;*/
  margin: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://img.freepik.com/premium-photo/saccharum-spontaneum-flower-kans-grass-wild-sugarcane-grass-flower_51012-144.jpg?w=2000");
}

@media only screen and (max-width: 600px) {
  .externallyAided {
    width: 100%;
    border: 2px solid grey;
    padding: 20px;
    border-radius: 25px;
    justify-content: "center";
    /*opacity:0.5;*/
    margin: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://img.freepik.com/premium-photo/saccharum-spontaneum-flower-kans-grass-wild-sugarcane-grass-flower_51012-144.jpg?w=2000");
    position: relative;
    right: 50px;
  }
}

.tribalDevelopment:nth-child(even) {
  /*border: 2px solid green;*/
  background: #f9faf2;
  padding: 20px;
  border-radius: 50px;
  justify-content: "center";
  list-style: none;

  margin: 50px;
}

.tribalDevelopment:nth-child(odd) {
  /*border: 2px solid green;*/
  background: #e7f6db;
  padding: 20px;
  border-radius: 50px;
  justify-content: "center";
  list-style: none;
  text-align: center;
  margin: 50px;
}

@media only screen and (max-width: 600px) {
  .tribalDevelopment:nth-child(even) {
    /*border: 2px solid green;*/
    background: #f9faf2;
    padding: 20px;
    width: 100%;
    border-radius: 50px;
    justify-content: "center";
    list-style: none;
    margin: 50px;
  }

  .tribalDevelopment:nth-child(odd) {
    /*border: 2px solid green;*/
    background: #e7f6db;
    padding: 20px;
    width: 100%;
    border-radius: 50px;
    justify-content: "center";
    list-style: none;
    text-align: center;
    margin: 50px;
  }

  .tribalcenter {
    position: relative;
    right: 75px;
  }
}

@media only screen and (max-width: 600px) {
  .bestpractices {
    /* border: 2px solid green; */
    padding: 38px;
    border-radius: 20px;
    justify-content: "center";
    margin: 10px;
    position: relative;
    left: 40px;
    /*background-color:#F9FAF2;*/

    /* box-shadow: 5px 10px #efe8e8; */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
  }
}

@media only screen and (max-width: 600px) {
  .secondary_sider_menu {
  }
}

.svg-map__location {
  fill: #8ffbbb;
  cursor: pointer;
}

.svg-map__location:hover {
  fill: #fecc4d;
  cursor: pointer;
}
.svg-map__location:active {
  fill: #0c3204;
  cursor: pointer;
}
.svg-map {
  width: 40%;
  height: auto;
  stroke: #0c3204;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  /* background: url(/img/method-draw-image.svg); */
  background-repeat: no-repeat;
  background-position: 117px 15px;
  background-size: 90%;
}

.svg-map__location[aria-checked="true"] {
  fill: #126909;
  stroke: none;
}

.distcardthiruvallur {
  padding: 38px;
  border-radius: 20px;
  justify-content: "center";
  justify-items: center;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
  width: 308px;
  height: 250px;
  flex-wrap: wrap;
  display: flex;
}
.displaythiruvallur {
  display: flex;
}

@media only screen and (max-width: 600px) {
  h4 {
    font-size: 20px;
  }
}

.list:nth-child(even) {
  background: #4c954c;

  color: white;
}
.list:nth-child(odd) {
  background: #d6e6a2;
}
.list {
  justify-content: "center";
  text-align: center;
  padding: 20px;
  border-radius: 30px;
  list-style: none;
  margin: 20px;
  height: auto;
}
@media only screen and (max-width: 600px) {
  .list:nth-child(odd) {
    background: #d6e6a2;
  }
  .list {
    justify-content: "center";
    text-align: center;
    padding: 20px;
    border-radius: 30px;
    list-style: none;
    margin: 20px;
    height: auto;
    position: relative;
    right: 20px;
  }
  .forest {
    position: relative;
    right: 20px;
  }
}

.forest {
}
.objectivetitle {
  text-align: center;
}
.Activities {
  border: 2px solid green;
  padding: 20px;
  border-radius: 50px;
  justify-content: "center";
  text-align: center;
  margin: 50px;
}
@media only screen and (max-width: 600px) {
  .Activities {
    border: 2px solid green;
    padding: 20px;
    border-radius: 50px;
    justify-content: "center";
    text-align: center;
    margin: 30px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .Activities1 {
    margin-left: -20px;
    padding-right: 15px;
  }
}

.moreActivities {
  text-align: center;
}

.distcard {
  padding: 38px;
  border-radius: 20px;
  /* justify-content: "center";
  justify-items: center; */
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px;
  width: 360px;
  height: auto;
  background-color: #278e1963;
}

.pvimage {
  width: 50%;
  justify-content: center;
}

.pvcenter {
  justify-content: center;
  display: flex;
}

.biopdf {
  text-align: end;
  display: block;
}

/* table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
 */

.marginbottomofficer {
  margin-bottom: 20px;
}

.ofcname {
  font-size: 13px;
  margin-left: 15px;
  align-self: center;
}

.forest_type_card {
  width: 250px;
  height: 150px;
  border-radius: 10px;
  background-color: #04aa6d;
}
.hover:hover {
  border: "5px solid red";
}

/* Date Selector Css Start */
.container {
  font-family: "Poppins";
  padding: 10px;
  width: 80%;
  height: 45vh;
}

.scrollableContainer {
  height: 40vh;
}

.dateList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listItem {
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.listItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.selected {
  background-color: #4d804d;
  color: #ffffff;
}
.selected:hover {
  background-color: #4d804d;
  color: #ffffff;
}

.nestedMonths {
  list-style-type: none;
  padding-left: 16px;
}

.collapseContainer {
  margin-top: 16px;
  height: 40vh;
}

.panelItem {
  background-color: #f0f0f0;
  border-radius: 4px;
}
.panelItem:hover {
  background-color: #335533;
  color: #ffffff;
  border-radius: 4px;
}

.panelItem:hover .ant-collapse-header .ant-collapse-header-text {
  color: #ffffff;
}

.panelItem .ant-collapse-header {
  font-family: "Poppins";
  color: #000000;
}

.panelItemSelected {
  background-color: #335533;
}

.panelItemSelected .ant-collapse-header .ant-collapse-header-text {
  color: #ffffff;
}
.scrollbars {
  width: 100%;
  height: 200px;
}

.scrollbars .thumb-vertical {
  background-color: rgba(0, 0, 0, 0.3);
}

.scrollbars .thumb-vertical:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.scrollbars .thumb-vertical:active {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Date Selector CSS End */
.home-tag{
  text-decoration: underline !important;
  color:#2f57005c;
  font-weight: bold;
  font-size: 18px;
}

@keyframes blink {
  0% { color: red; }
  25% { color: rgb(0, 255, 251); }
  50% { color: rgb(0, 255, 0); }
  75% { color: yellow; }
  100% { color: rgb(55, 128, 254); }
}

.blink-text {
  animation: blink 2s infinite; /* Adjust the duration as needed */
}

